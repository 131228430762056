import { Title } from '@solidjs/meta';
import {
	Button,
	Container,
	Dialog,
	DialogContent,
	Heading,
	HorizontalRule,
	Link,
	Page,
	Section,
	TextLink,
	TroonRewardsLogo,
} from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { createSignal, createUniqueId, Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { AuthFlow } from '../../partials/auth/auth';
import { RewardsCard } from '../../components/rewards-card';
import { UserRewardsLevel } from '../../graphql';
import { Grid, GridHalf } from '../../components/layouts/grid';
import { FrequentlyAskedQuestions } from '../../components/faqs';
import { VideoSection } from '../../components/layouts/video-section';
import { useUser } from '../../providers/user';
import type { ParentProps, JSX } from 'solid-js';
import type { RouteDefinition } from '@solidjs/router';

export default function TroonRewardsPage() {
	const [authOpen, setAuthOpen] = createSignal(false);
	const authDialogId = createUniqueId();
	const user = useUser();

	return (
		<>
			<Title>Troon Rewards | Troon</Title>

			<NoHydration>
				<Hero fullWidth src={`${getConfigValue('IMAGE_HOST')}/digital/hero/rewards.png`}>
					<Heading as="h1" class="w-full max-w-md px-8 text-center text-white">
						<span class="sr-only">Troon Rewards</span>
						<TroonRewardsLogo class="w-full max-w-3xl" />
					</Heading>
				</Hero>
			</NoHydration>

			<Page>
				<Section>
					<Container>
						<VideoSection
							poster={`${getConfigValue('IMAGE_HOST')}/digital/video/rewards-poster.jpg`}
							code="jUsRNiDEMTo"
							type="youtube"
						>
							<Heading as="h2">Earn Free Rounds of Golf at Troon Facilities Worldwide</Heading>
							<p>
								Start earning FREE ROUNDS of golf and get STATUS at Troon facilities worldwide! Join Troon Rewards® to
								earn free rounds of golf and discounts on merchandise and golf fees. Also, receive special offers,
								access to exclusive events and Callaway® promotions. The more you play, the more you save! Sign up in
								the golf shop or by clicking below and get started today!
							</p>

							<div class="flex gap-4">
								<Show when={!user()}>
									<AuthButton id={authDialogId} open={authOpen()} setOpen={setAuthOpen}>
										Sign up for free
									</AuthButton>
								</Show>
								<Button as={Link} href="/rewards/redemption" appearance="secondary" class="size-fit grow-0">
									Redemption Options
								</Button>
							</div>
						</VideoSection>
					</Container>
				</Section>

				<Container>
					<HorizontalRule />
				</Container>

				<Container>
					<Section class="gap-8 text-center md:gap-16">
						<NoHydration>
							<div class="mx-auto flex max-w-4xl flex-col gap-4">
								<Heading as="h2">The more you play, the more you save!</Heading>
								<p>
									Join the all-new Troon Access program to unlock exclusive savings at more than 150 Troon courses
									including Kapalua, Troon North, and Gamble Sands.
								</p>
							</div>

							<div class="grid w-full grid-cols-3 gap-16">
								<Benefit>
									<svg aria-hidden class="size-16">
										<use href="#flag" />
									</svg>
									<Heading as="h3">Play & Earn Points</Heading>
									<p>
										Book your tee times at any participating Troon facility and earn one point for every dollar spent on
										golf fees, merchandise, and lessons.
									</p>
								</Benefit>
								<Benefit>
									<svg aria-hidden class="size-16">
										<use href="#list" />
									</svg>
									<Heading as="h3">Track Your Status</Heading>
									<p>
										As you accumulate points, track your rewards status through your Troon Rewards account. Unlock
										exclusive benefits as you reach higher status levels.
									</p>
								</Benefit>
								<Benefit>
									<svg aria-hidden class="size-16">
										<use href="#tag" />
									</svg>
									<Heading as="h3">Redeem Your Rewards</Heading>
									<p>
										Redeem your points for free rounds of golf, discounts on future purchases, and other exclusive
										rewards at Troon locations worldwide.
									</p>
								</Benefit>
							</div>
						</NoHydration>

						<Show when={!user()}>
							<div class="mx-auto text-center">
								<AuthButton id={authDialogId} open={authOpen()} setOpen={setAuthOpen}>
									Get started <Icon name="arrow-right-md" />
								</AuthButton>
							</div>
						</Show>
					</Section>
				</Container>

				<NoHydration>
					<Section appearance="primary" class="gap-8 text-center md:gap-16">
						<Container class="mx-auto flex max-w-4xl flex-col gap-4">
							<Heading as="h2">Unlock Exclusive Discounts with Troon Rewards Status</Heading>
							<p>
								Earn rewards points for every round of golf, lesson, or purchase, and progress through Silver, Gold, and
								Platinum status. Higher status means bigger discounts—up to 20% off tee times!
							</p>
						</Container>

						<Container class="grid max-w-sm grid-cols-12 gap-x-8 gap-y-6 sm:max-w-full lg:max-w-7xl xl:gap-x-16">
							<div class="col-span-12 sm:col-span-6 lg:col-span-4">
								<RewardsCard level={UserRewardsLevel.Silver} />
							</div>
							<div class="col-span-12 sm:col-span-6 lg:col-span-4">
								<RewardsCard level={UserRewardsLevel.Gold} />
							</div>
							<div class="col-span-12 sm:col-span-6 sm:col-start-4 lg:col-span-4">
								<RewardsCard level={UserRewardsLevel.Platinum} />
							</div>
						</Container>

						<Container class="mx-auto flex max-w-4xl flex-col gap-4">
							<p>
								Please see the{' '}
								<TextLink href="/rewards/terms" class="text-white underline">
									Troon Rewards Terms & Conditions
								</TextLink>
								.
							</p>
						</Container>
					</Section>
				</NoHydration>

				<Container>
					<Grid>
						<GridHalf>
							<Section>
								<Heading as="h2" size="h2">
									Frequently asked questions
								</Heading>
								<p>
									If you are a Troon Rewards member who did not receive Troon Rewards points for a tee time or
									merchandise purchase please click the button below.
								</p>
								<div>
									<Button class="size-fit shrink" appearance="secondary" as={Link} href="/rewards/points-request">
										Request missing points
									</Button>
								</div>
							</Section>
						</GridHalf>

						<GridHalf border>
							<FrequentlyAskedQuestions content={faqs} />
						</GridHalf>
					</Grid>
				</Container>

				<Dialog key="auth-rewards" open={authOpen()} onOpenChange={setAuthOpen} id={authDialogId}>
					<DialogContent autoWidth noPadding noClose floatingClose>
						<AuthFlow onComplete={() => setAuthOpen(false)} />
					</DialogContent>
				</Dialog>
			</Page>

			<NoHydration>
				<svg width="0" height="0" class="hidden">
					<symbol fill="none" viewBox="0 0 60 60" id="tag">
						<path
							d="m11.9 19.1-.9 9.1V31c0 .6.3 1.1.6 1.6.3.6.8 1 1.7 2l13 13c2 2 3 3 4 3.3 1 .3 2.2.3 3.2 0 1.1-.4 2.1-1.3 4-3.3l10-10c2-1.9 3-2.9 3.3-4 .3-1 .3-2.1 0-3.1-.4-1.2-1.3-2.2-3.3-4.1l-13-13c-1-1-1.4-1.4-2-1.7A5 5 0 0 0 31 11h-2.7l-9 .9c-2.5.2-3.6.3-4.6.8a5 5 0 0 0-1.9 2c-.5.9-.6 2-.8 4.4Z"
							fill="#E6F2F3"
						/>
						<path d="M24.3 24.3a2.5 2.5 0 1 0-3.6-3.6 2.5 2.5 0 0 0 3.6 3.6Z" fill="#E6F2F3" />
						<path
							d="m11.9 19.1-.9 9.1V31c0 .6.3 1.1.6 1.6.3.6.8 1 1.7 2l13 13c2 2 3 3 4 3.3 1 .3 2.2.3 3.2 0 1.1-.4 2.1-1.3 4-3.3l10-10c2-1.9 3-2.9 3.3-4 .3-1 .3-2.1 0-3.1-.4-1.2-1.3-2.2-3.3-4.1l-13-13c-1-1-1.4-1.4-2-1.7A5 5 0 0 0 31 11h-2.7l-9 .9c-2.5.2-3.6.3-4.6.8a5 5 0 0 0-1.9 2c-.5.9-.6 2-.8 4.4h0Z"
							stroke="#027F88"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M24.3 24.3a2.5 2.5 0 1 0-3.6-3.6 2.5 2.5 0 0 0 3.6 3.6Z"
							stroke="#027F88"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</symbol>
					<symbol viewBox="0 0 60 60" fill="none" id="list">
						<path
							d="M27.5 42.5H50M20 37.5L13.75 45L10 42.5M27.5 30H50M20 25L13.75 32.5L10 30M27.5 17.5H50M20 12.5L13.75 20L10 17.5"
							stroke="#027F88"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</symbol>
					<symbol fill="none" viewBox="0 0 61 60" id="flag">
						<path
							d="M10.333 39.218c14.546-11.374 25.455 11.373 40 0V10.783c-14.545 11.373-25.454-11.374-40 0v28.434Z"
							fill="#E6F2F3"
						/>
						<path
							d="M10.333 52.5V39.218m0 0c14.546-11.374 25.455 11.373 40 0V10.783c-14.545 11.373-25.454-11.374-40 0v28.434Z"
							stroke="#027F88"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</symbol>
				</svg>
			</NoHydration>
		</>
	);
}

function Benefit(props: ParentProps) {
	return <div class="col-span-3 flex flex-col items-center gap-6 text-center lg:col-span-1">{props.children}</div>;
}

function AuthButton(props: ParentProps<{ open: boolean; id: string; setOpen: (o: boolean) => void }>) {
	return (
		<Button
			as={Link}
			aria-haspopup="dialog"
			aria-expanded={props.open}
			aria-controls={props.open ? `${props.id}-content` : undefined}
			onClick={(e) => {
				e.preventDefault();
				props.setOpen(true);
			}}
			href="/auth"
			class="size-fit grow-0"
		>
			{props.children}
		</Button>
	);
}

const faqs: Record<string, () => JSX.Element> = {
	'How do I acquire Troon Rewards points?': () => (
		<p>
			To receive Troon Rewards points, you must present your Troon Rewards number at the time of check-in at the golf
			shop front counter in order to receive points for your own greens fee and/or merchandise (pre-tax).
		</p>
	),
	'Can I receive points for food and beverage?': () => (
		<p>Troon Rewards points may not be earned for purchases of food or beverages.</p>
	),
	'I paid for my guest’s round, why did I only get points for myself?': () => (
		<p>
			Troon Rewards is a program for individuals. As a member of Troon Rewards, you may earn points for your own green
			fees and/or merchandise (pre-tax) in the golf shop.
		</p>
	),
	'How can I receive points for a visit that did not get registered to my Troon Rewards account?': () => (
		<p>
			If points are missing from your account, please submit a request for missing points within 90 days of the original
			purchase date by clicking <a href="https://troon.com/troon-golf-rewards-program/points-request/">here</a>. A
			request for points past 90 days will not be added to member accounts.
		</p>
	),
	'Do Troon Rewards points expire?': () => (
		<p>
			Troon Rewards points do not expire, provided that member has earned or redeemed Troon Rewards points in the last
			eighteen (18) months. If a Member’s account is inactive for eighteen (18) consecutive months that Member will
			forfeit all accumulated points outside of the 18-month window, based on the date of the last transaction. Once
			points are forfeited or expire, they cannot be reinstated.
		</p>
	),
	'What do the points go towards or what are the rewards?': () => (
		<>
			<p>The following 2 rewards/benefits are mutually exclusive. Use of one will not detract from the other.</p>
			<ol>
				<li>
					Troon Rewards members can use their accumulated Troon Rewards points towards complimentary rounds of golf
					(restrictions apply).
				</li>
				<li>
					Earn a Status Level providing you with discounts of up to 20% off golf and/or merchandise in the golf shop.
				</li>
			</ol>
		</>
	),
	'How can I review my account?': () => (
		<p>
			Your Troon Rewards account and information is available only by login at{' '}
			<a href="/troon-golf-rewards-program/">www.TroonRewards.com</a>. Your Troon Rewards ID and email address are
			required for login.
		</p>
	),
	'How do I receive my complimentary rounds of golf?': () => (
		<p>
			Upon earning Troon Rewards points, you are able to redeem those points towards a complimentary round of golf based
			on the redemption table/schedule. You can login to your account at{' '}
			<a href="/troon-golf-rewards-program/">www.TroonRewards.com</a> to view your points, which are valid towards
			complimentary rounds of golf. Simply book your round according to the terms & conditions of the program (and let
			the golf shop associate know that you would like to redeem your Troon Rewards points during booking). The staff
			will redeem the applicable points when you check in for your round and they will be removed from your account.
		</p>
	),
	'How do I use Troon Rewards points and cash for rounds of golf?': () => (
		<p>
			Troon Rewards points can now be combined with cash to redeem rounds of golf. Please see the{' '}
			<a href="/troon-golf-rewards-program/troon-rewards-redemption/">Troon Rewards Redemption page</a>, which shows the
			number of points and cash required per Troon facility. Be sure to check the month of the year, as the requirements
			change throughout the year.
		</p>
	),
	'How many Troon Rewards points are required for a complimentary round of golf?': () => (
		<p>
			Troon Rewards points required vary based on the season and the course you are playing. Please see the{' '}
			<a href="/troon-golf-rewards-program/troon-rewards-redemption/">Troon Rewards Redemption page</a> for full
			details.
		</p>
	),
	'How do the status levels work?': () => (
		<>
			<p>
				Status levels are based on the number of Troon Rewards points accumulated within a calendar year. Once you have
				achieved a status level, you will receive the associated discount on your next purchase. You will retain your
				status level for the remainder of the calendar year in which it is achieved through the end of the next calendar
				year.
			</p>
			<ul>
				<li>2,000 points/year = Silver status & 10% discount</li>
				<li>4,000 points/year = Gold status & 15% discount</li>
				<li>8,000 points/year = Platinum status & 20% discount</li>
			</ul>
			<p>
				Status level discounts can be applied to the Troon Rewards member’s personal green fees and/or merchandise only.
			</p>
		</>
	),
};

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
